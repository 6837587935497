import React, { useEffect, useRef } from 'react';
import lcjs from '@arction/lcjs';
import getEEGStream from '../utils/eeg';

// Extract required parts from LightningChartJS.
const {
  lightningChart,
  SolidFill,
  SolidLine,
  AxisScrollStrategies,
  AxisTickStrategies,
  ColorRGBA,
  UIElementBuilders,
  Themes,
} = lcjs

const xIntervalMax = 40
let sessionStartTimeMS = null
let lastDrawnTimeMS = 0

const MAX_TRIES = 10

const Chart = ({ sessionId, channels, min, max, startTime, delayMilliseconds }) => {
  let buffer: any[] = [] // elements are 12 second blocks
  const wsConsumer = useRef(null)
  const maxTries = useRef(MAX_TRIES)

  function connectWs() {
    const ws = new WebSocket(`ws${window.location.protocol === 'https:' ? 's' : ''}://${window.location.host}/proxy/eeg-consumer/websocket`);

    ws.onopen = function () {
      console.log("Websocket open", ws)
      ws.send(JSON.stringify({ type: "start", data: sessionId }));
      maxTries.current = MAX_TRIES
    };

    ws.onmessage = (evt: any) => {
      const eegStream = getEEGStream(evt.data);
      buffer.push(eegStream);
    };

    ws.onerror = function (event) {
      //console.error(event);
    };

    ws.onclose = function (event) {
      if (event.wasClean) {
        console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
      } else if (maxTries.current > 0) {
        console.log('[close] Connection closed. Reconnect will be attempted in 1 second.', event.reason);
        setTimeout(function () {
          maxTries.current -= 1
          connectWs();
        }, 1000);
      }
      else {
        // e.g. server process killed or network down
        // event.code is usually 1006 in this case
        console.error('[close] Connection died');
      }
    };

    wsConsumer.current = ws
  }
  connectWs()

  const channelIntervalY = Math.abs(max - min)
  sessionStartTimeMS = startTime.getTime()
  const chartId = `chart-${sessionId}`

  useEffect(() => {
    console.log("Session selected:", sessionId)

    console.log(Themes.darkGold)
    const chart = lightningChart()
      .ChartXY({
        theme: {
          ...Themes.darkGold,
        },
        container: chartId,
        
      }).setTitle(sessionId)
    const axisX = chart
      .getDefaultAxisX()
      .disableAnimations()
      .setScrollStrategy(AxisScrollStrategies.progressive)
      .setInterval(-xIntervalMax, 0)
    const axisY = chart.getDefaultAxisY()
      .setTickStrategy(AxisTickStrategies.Empty)
      .disableAnimations()
      .setScrollStrategy(AxisScrollStrategies.expansion)
      .setInterval(-channelIntervalY / 2, channels.length * channelIntervalY)

    const series = channels.map((channelName, iChannel) => {
      // Create line series optimized for regular progressive X data.
      const nSeries = chart.addLineSeries({
        dataPattern: {
          // pattern: 'ProgressiveX' => Each consecutive data point has increased X coordinate.
          pattern: 'ProgressiveX',
          // regularProgressiveStep: true => The X step between each consecutive data point is regular (for example, always `1.0`).
          regularProgressiveStep: false,
        }
      })
        .setName(channelName)
        // Default color, but thickness = 1
        .setStrokeStyle(style => style.setThickness(1))
        .setMouseInteractions(false).setDataCleaning({ minDataPointCount: xIntervalMax })

      // const series = new Array(channelNum).fill(0).map((_, iChannel) => {
      //   // Create line series optimized for regular progressive X data.
      //   const nSeries = chart.addPointSeries()
      //     .setCursorEnabled(false)
      //     .setMouseInteractions(false)
      //     .setName(`${iChannel + 1}`).setPointSize(1)
      //     // Default color, but thickness = 1
      //     .setMouseInteractions(false)
      //     // Enable automatic data cleaning.
      //     .setMaxPointCount(xIntervalMax)
      //   // Add custom tick for each channel.
      chart
        .getDefaultAxisY()
        .addCustomTick(UIElementBuilders.AxisTick)
        .setValue((channels.length - (1 + iChannel)) * channelIntervalY)
        .setTextFormatter(() => channelName)
        .setGridStrokeStyle(
          new SolidLine({
            thickness: 1,
            fillStyle: new SolidFill({ color: ColorRGBA(255, 255, 255, 60) }),
          }),
        )

      return nSeries
    });

    const xAxis = chart.getDefaultAxisX()

    const draw = (timestamp) => {
      if (!sessionStartTimeMS) {
        return requestAnimationFrame(draw)
      }
      const currentTimeMS = Date.now()

      if (buffer.length > 0) {


        const block = buffer[0] // Data of x seconds
        const blockTimeMS = (block.data.length / block.samplefreq) * 1000
        const sampleIntervalMS = (blockTimeMS / block.data.length)

        if (Math.random() < 1 / 60 / 30) {
          // simulate detected seizures
          const xAxisBand = xAxis.addBand();

          const sampleTimeMS = (lastDrawnTimeMS - sessionStartTimeMS) / 1000;
          xAxisBand
            .setValueStart(Math.abs(sampleTimeMS - 12))
            .setValueEnd(sampleTimeMS)
            .setName('Seizure detected')
        }

        let lastIndex = 0
        for (const si in block.data) {
          const sampleIndex = parseInt(si)
          const sampleTimeMS = block.starttime * 1000 + sampleIndex * sampleIntervalMS;
          const sampleChannels = block.data[sampleIndex] // Data of 1 sample
          if (sampleTimeMS > lastDrawnTimeMS) {
            if (sampleTimeMS < currentTimeMS - delayMilliseconds) {
              for (const channel in sampleChannels) {
                const v = sampleChannels[parseInt(channel)]
                const y = ((channels.length - 1) - parseInt(channel)) * channelIntervalY + v;
                series[channel].add({ x: (sampleTimeMS - sessionStartTimeMS) / 1000, y })
              }
              lastDrawnTimeMS = sampleTimeMS
              lastIndex = sampleIndex
            }
          }
          else {
            lastIndex = sampleIndex
          }

          if (lastIndex == block.data.length - 1) {
            buffer.shift()
          }
        }
      }

      requestAnimationFrame(draw)
    }

    requestAnimationFrame(draw);
    return () => {
      // componentWillUnmount events
      wsConsumer.current.send(JSON.stringify({ type: "stop", data: sessionId }));
    }
  }, []);

  return <div>
    <div id={chartId} className='chart'></div>
  </div>
}

export default Chart;