import React, { useState, useEffect } from 'react';

import eegSessionService from '../services/EEGSessionService';
import Chart from './Chart';
import { Typography, TextField, Button, Box, CardActions, CardContent } from '@mui/material';
import { EEGSession } from '../rest';


const Home = () => {
  const [activeSessions, setActiveSessions] = useState<string[]>(null);
  const [selectedSessionId, setSelectedSessionId] = React.useState<string>(null);
  const [delayMilliseconds, setDelayMilliseconds] = React.useState<number>(2000);
  const [selectedSession, setSelectedSession] = React.useState<EEGSession>(null);

  useEffect(() => {
    eegSessionService.getSessions().then(r => setActiveSessions(r));
  }, []);

  useEffect(() => {
    if (selectedSessionId) {
      eegSessionService.getEEGSession(selectedSessionId).then(s => { setSelectedSession(s) });
    }
  }, [selectedSessionId]);

  const handleChangeDelayMilliseconds = (e) => {
    setDelayMilliseconds(e.target.value)
  }

  return activeSessions && <>

    {selectedSession && <Chart
      key={selectedSession.key}
      sessionId={selectedSession.id}
      channels={selectedSession.montage}
      min={selectedSession.min}
      max={selectedSession.max}
      startTime={selectedSession.startTime}
      delayMilliseconds={delayMilliseconds} />}


    <Typography variant="h6">Select from active sessions:</Typography>
    {
      activeSessions.map(s => <Button key={s} color="primary" onClick={() => setSelectedSessionId(s)}>{s}</Button>)
    }
    {
      activeSessions.length == 0 && <Typography>No active sessions found</Typography>
    }
  <Box mt={2}>
    <TextField
      id="delay-delect"
      label="Plot delay (ms)"
      value={delayMilliseconds}
      onChange={handleChangeDelayMilliseconds}
    />
</Box>

  </>
}

export default Home;