/* tslint:disable */
/* eslint-disable */
/**
 * Realtime seizure detection
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EEGSession,
    EEGSessionFromJSON,
    EEGSessionToJSON,
} from '../models';

export interface AddSessionRequest {
    requestBody: { [key: string]: object; };
}

export interface GetEEGsessionRequest {
    sessionId: string;
}

/**
 * 
 */
export class EegsessionApi extends runtime.BaseAPI {

    /**
     */
    async addSessionRaw(requestParameters: AddSessionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EEGSession>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling addSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/eegsession`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EEGSessionFromJSON(jsonValue));
    }

    /**
     */
    async addSession(requestParameters: AddSessionRequest, initOverrides?: RequestInit): Promise<EEGSession> {
        const response = await this.addSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getEEGsessionRaw(requestParameters: GetEEGsessionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EEGSession>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling getEEGsession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/eegsession/{sessionId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EEGSessionFromJSON(jsonValue));
    }

    /**
     */
    async getEEGsession(requestParameters: GetEEGsessionRequest, initOverrides?: RequestInit): Promise<EEGSession> {
        const response = await this.getEEGsessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Used to retrieve all active sessions
     */
    async getSessionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/eegsession`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Used to retrieve all active sessions
     */
    async getSessions(initOverrides?: RequestInit): Promise<Array<string>> {
        const response = await this.getSessionsRaw(initOverrides);
        return await response.value();
    }

}
