import { EegsessionApi } from '../rest/apis/EegsessionApi';

/**
 * Contextual data querying.
 * @memberof Services
 */
export class EEGSessionService {

  /**
   * The context api.
   */
  api: EegsessionApi;

  /**
   * @constructor
   */
  constructor () {
    this.api = new EegsessionApi()
  }

  getSessions () {
    return this.api.getSessions();
  }

  getEEGSession(eegSessionId:string) {
    return this.api.getEEGsession({sessionId:eegSessionId})
  }

  
}

export default new EEGSessionService();