/* tslint:disable */
/* eslint-disable */
/**
 * Realtime seizure detection
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Patient,
    PatientFromJSON,
    PatientFromJSONTyped,
    PatientToJSON,
} from './Patient';

/**
 * Describes an EEG session. Sessions reference to a patient and the device used 
 * @export
 * @interface EEGSession
 */
export interface EEGSession {
    [key: string]: object | any;
    /**
     * 
     * @type {string}
     * @memberof EEGSession
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof EEGSession
     */
    name?: string;
    /**
     * 
     * @type {Patient}
     * @memberof EEGSession
     */
    patient: Patient;
    /**
     * 
     * @type {Date}
     * @memberof EEGSession
     */
    readonly startTime: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof EEGSession
     */
    montage: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof EEGSession
     */
    min: number;
    /**
     * 
     * @type {number}
     * @memberof EEGSession
     */
    max: number;
}

export function EEGSessionFromJSON(json: any): EEGSession {
    return EEGSessionFromJSONTyped(json, false);
}

export function EEGSessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): EEGSession {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
            ...json,
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'patient': PatientFromJSON(json['patient']),
        'startTime': (new Date(json['startTime'])),
        'montage': json['montage'],
        'min': json['min'],
        'max': json['max'],
    };
}

export function EEGSessionToJSON(value?: EEGSession | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
            ...value,
        'id': value.id,
        'name': value.name,
        'patient': PatientToJSON(value.patient),
        'montage': value.montage,
        'min': value.min,
        'max': value.max,
    };
}

