import React from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';

import './styles/style.less';

import Home from './components/Home';
import theme from './theme';
import { Divider } from '@mui/material';

const mdTheme = createTheme(theme);

const Main = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const auth = true; // TODO get auth info



  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return <ThemeProvider theme={mdTheme}>
    <CssBaseline />
    <AppBar position="static">
      <Toolbar>
        <img src="/assets/logo.png" height="20" />
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Real time seizure detection analyzer
        </Typography>

        {auth && (
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>Logout</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>

    </AppBar>

    <Box pt={5}>
      <Home />
      <Divider></Divider>
      <Typography component="p">See api documentation <a href="/api/ui">here</a></Typography>
    </Box>
  </ThemeProvider>
};

export default Main;